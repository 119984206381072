@import (reference) "variables";

body
{
  background: #f8f8f8;
}

.navbar-form
{
  border: 0 !important;
  box-shadow: 0 0 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

//.navbar-nav
//{
//  padding: 5px 0 0 0;
//}
.navbar
{
  margin-bottom: 0 !important;
}

.navbar-brand
{
  padding: 10px 15px 0px 15px !important;
}

.copertina_utente
{
  background: url(/assets/images/bg_foto_sopra_user.jpg);
  background-position: bottom;
  min-height: 300px;
}

.menu-utente a:link, .menu-utente a:visited
{
  color: #f0ad4e;
}

.menu-utente a:hover
{
  color: #333;
}

.btn-blu
{
  background: #3381c4;
  border: 1px solid #245580;
  color: #fff;
}

.btn-no-link-blu
{
  background: #3381c4;
  border: 1px solid #245580;
  color: #fff;
}

.btn-blu:hover
{
  background: #245580;
  border: 1px solid #245580;
  color: #fff;
}

.btn-blu-light
{
  border: 3px solid #245580;
  color: #245580;
}

.btn-no-link-blu-light
{
  border: 3px solid #245580;
  color: #245580;
}

.btn-blu-light:hover
{
  border: 3px solid #3381c4;
  color: #3381c4;
}

.btn-arancio
{
  background: #ff7f00;
  border: 1px solid #c13b1e;
  color: #fff;
  text-shadow: 1px 1px #c13b1e;
}

.btn-no-link-arancio
{
  background: #ff7f00;
  border: 1px solid #c13b1e;
  color: #fff;
  text-shadow: 1px 1px #c13b1e;
}

.btn-arancio:hover
{
  background: #ec5605;
  border: 1px solid #c13b1e;
  color: #fff;
  text-shadow: 1px 1px #c13b1e;
}

.btn-arancio-light
{
  border: 3px solid #ff7f00;
  color: #c13b1e;
}

.btn-no-link-arancio-light
{
  border: 3px solid #ff7f00;
  color: #c13b1e;
}

.btn-no-link-grigio-light
{
  border: 3px solid #999;
  color: #999;
}

.btn-arancio-light:hover
{
  border: 3px solid #c13b1e;
  color: #c13b1e;
}

.btn-verde
{
  background: #03a443;
  border: 1px solid #027d33;
  color: #fff;
  text-shadow: 1px 1px #027d33;
}


.btn-no-link-verde
{
  background: #03a443;
  border: 1px solid #027d33;
  color: #fff;
  text-shadow: 1px 1px #027d33;
}

.btn-verde:hover
{
  background: #027d33;
  border: 1px solid #01692b;
  color: #fff;
  text-shadow: 1px 1px #01692b;
}

.btn-verde-light
{
  border: 3px solid #027d33;
  color: #027d33;
}

.btn-no-link-verde-light
{
  border: 3px solid #027d33;
  color: #027d33;
}


.btn-verde-light:hover
{
  border: 3px solid #03a443;
  color: #03a443;
}

.bg-verde
{
  background: #03a443;
}

.bg-verde-scuro
{
  background: #027d33;
}

.btn-bianco
{
  background: #fff;
  border: 1px solid #fff;
  color: #000;
  &:hover{
    background: #f2f2f2;
  }
}


.btn-no-link
{
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-no-link-xs
{
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0;
}

@media (max-width: 1000px)
{
  .navbar-header
  {
    float: none;
  }
  .navbar-left, .navbar-right
  {
    float: none !important;
  }
  .navbar-toggle
  {
    display: block;
  }
  .navbar-collapse
  {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top
  {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse
  {
    display: none !important;
  }
  .navbar-nav
  {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li
  {
    float: none;
  }
  .navbar-nav > li > a
  {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in
  {
    display: block !important;
  }
}

.col-xs-12.no-padding
{
  padding: 0 !important;
}

.no-padding
{
  padding: 0 !important;
}

.padding-10
{
  padding: 10px;
}
.padding-5
{
  padding: 5px;
}

.padding-top-15
{
  padding-top: 15px;
}

.margin-top-15
{
  margin-top: 15px;
}

.margin-top-15
{
  margin-top: 15px;
}

.margin-top-30
{
  margin-top: 30px;
}

.margin-bottom-15
{
  margin-bottom: 15px;
}

.margin-right-15
{
  margin-right: 15px;
}

.margin-bottom-30
{
  margin-bottom: 30px;
}

.width-50 {
  min-width: 50px;
  width: 30px;
}


.width-100 {
  width:100px;
}


.width-200 {
  width:200px;
}

.width-300 {
  width:300px;
}

.width-400 {
  width:400px;
}

.block
{
  display: block;
}

.post
{
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px 0;
}

.media-heading
{
  line-height: 150%;
}

.onda_foto
{
  background: repeat-x url(../images/onda-effect.png) 0 0;
  background-position-x: 1px;
  height: 16px;
  margin: -14px auto;
  position: relative;
}

.img-64
{
  min-width: 64px;
  max-width: 64px;
}

a.link_bianco
{
  color: #fff;
}

a.link_bianco:hover
{
  text-decoration: none;
  color: #f0ad4e;
}

.color-white
{
  color: #fff;
}

.vcenter
{
  vertical-align: middle !important;
}

.bgorange
{
  background: #ff6600;
}

.color-orange
{
  color: #ff6600;
}

.color-bianco
{
  color: #fff;
}

.font-16
{
  font-size: 16px;
}

.font-14
{
  font-size: 14px;
}

.font-13
{
  font-size: 13px;
}

.fixed
{
  position: fixed;
  top: 0;
  left: 0;
}

.btn-like-on
{
  background: url(../images/pugnetto.png);
  background-position: bottom;
}

.btn-like-off
{
  background: url(../images/pugnetto.png);
  background-position: top;
}

.link-bianco
{
  color: #ffffff;
}

.link-bianco:hover
{
  text-decoration: none;
  color: #f0ad4e;
}

.pos-evidenza
{
  background-color: #ffd592;
}

.text-bold
{
  font-weight: bold;
}

.screen img
{
  width: 100%;
}

.screen
{
  width: 100%;
  max-height: 340px;
  overflow: hidden;
}

.min-height-90
{
  min-height: 90px;
}

.width-60
{
  width: 60px;
}

.display-inline
{
  display: inline !important;
}

.progress-bar
{
  color: #333;
}

.textarea-no-border
{
  border: none !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0) inset !important;
  resize: none;
}

/*
Reference:
http://www.bootstrapzen.com/item/135/simple-login-form-logo/
*/

*
{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.login .form-control
{
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  margin-bottom: 20px;
}

.bg-login
{
  background: url(/images/dash-bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-form
{
  margin-top: 60px;
  background: rgba(255,255,255,0.7);
  padding: 20px; /* border-radius: 10px; */
  border: 1px solid #fff;
}

form[role=login]
{
  color: #5d5d5d;
  background: #f2f2f2;
  padding: 26px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
form[role=login] img
{
  display: block;
  margin: 0 auto;
  margin-bottom: 35px;
}
form[role=login] input, form[role=login] button
{
  font-size: 18px;
  margin: 16px 0;
}
form[role=login] > div
{
  text-align: center;
}

.form-links
{
  text-align: center;
  margin-top: 1em;
  margin-bottom: 50px;
}
.form-links a
{
  color: #fff;
}
.fa-stack
{
  width: 2.5em !important;
}
.color-grigio
{
  color: #666;
}

.comments .media
{
  position: relative;
}

.comments .media:hover
{
  background-color: #f3f3f3;
}

.comments-remove
{
  position:absolute; top: 10px; right: 10px; display:none;
}

.comments-remove:hover
{
  cursor: pointer;
}

.comments .media:hover .comments-remove
{
  display:block;
}

.link-rosso, .link-rosso:hover
{
  color: Red;
}

.fakelink, .fakelink:hover
{
  color: #337ab7;
}

.fakelink:hover
{
  text-decoration: underline;
  cursor: pointer;
}

ul.notifiche li
{
  padding: 10px;
  border-bottom: 1px solid #ddd;
  min-width: 300px;
}

ul.notifiche li:hover
{
  cursor:pointer;
  background: #f2f2f2;
}

.notify-from
{
  color: #337ab7
}
.notify-message
{
  display:block;
  font-size: 12px;
}
.notify-time
{
  color: #666;
  font-size: 12px;
}

.notify-project
{
  color: #337ab7;
}

#viewall
{
  background: #f2f2f2;
}

#viewall:hover
{
  background: #dddddd;
}

.img-32 {
  min-width: 32px;
  max-width: 32px;
}