.navbar-mosajco {
  border-radius: 0;

  .user-badge {
    color: whitesmoke;
    font-size: 14px;
    display: inline-block;
    padding: 5px 0;
    cursor: pointer;
    margin-right:20px;
    img {
      border-radius: 3px;
      border: 1px solid black;
      max-width: 40px;
    }

    .dropdown-menu {
      background: #333;
      li {
        a {
          color: white;
          &:hover {
            color: #333;
          }
        }
      }
    }
  }
}

.container-level {
  display: flex;
  float: left;
  height: @navbar-height;
  //background-color: red;
  //min-width: 80px;
  padding-left: 5px;


  align-items: center;

  .container-certificate {
    i {
      color:white;
      font-size: 30px;
    }
  }

  .level, .pt-exp {
    padding-left: 5px;
    padding-right: 15px;
    color: #FF7F00;
  }
}

//.container-level {
//  float: left;
//  position: relative;
//  min-width: 120px;
//  max-width: 200px;
//
//
//  //.container-certificate {
//  //  .clearfix();
//  //  width: 40px;
//  //  margin-top: 5px;
//  //  position: relative;
//  //
//  //  .certificate {
//  //    position: relative;
//  //    display: inline-block;
//  //
//  //    i {
//  //      font-size: 30px;
//  //      color: #fff;
//  //      margin-right: 10px;
//  //    }
//  //    span.level {
//  //      position: absolute;
//  //      top: 10px;
//  //      left: 10px;
//  //      font-size: 16px;
//  //      font-weight: bold;
//  //      text-shadow: -1px 0px 1px #000;
//  //      color: #FF7F00;
//  //    }
//  //  }
//  //  .notification {
//  //    position: absolute;
//  //    top: -5px;
//  //    right: -3px;
//  //    span.alert {
//  //      font-size: 15px;
//  //      color: #fff;
//  //      width: 20px;
//  //      height: 20px;
//  //      border-radius: 50%;
//  //      display: block;
//  //      background: #f00;
//  //      line-height: 17px;
//  //      padding: 0;
//  //      text-align: center;
//  //    }
//  //  }
//  //}
//  span.pt-exp {
//    float: left;
//    color: #FF7F00;
//    line-height: 45px;
//    margin-right: 30px;
//  }
//}


.container-credits{
  padding: 8px 0 8px 10px;
  float: right;
  min-width: 150px;

  .credits{
    position: relative;
    width: 42px;
    float:right;
    margin-right: 10px;
    i{
      color: #169636;
      font-size: 24px;
      display: block;
      float: left;
      padding: 5px 10px 0;
    }
    .notification {
      position: absolute;
      top: -5px;
      right: -3px;
      span.alert {
        font-size: 15px;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: block;
        background: #f00;
        line-height: 17px;
        padding: 0;
        text-align: center;
      }
    }
  }
}