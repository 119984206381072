//@import "bootstrap/bootstrap";
//@import (reference) "bootstrap/mixins";
//@import (inline) "../css/bootstrap.min.css";
//@import (inline) "../css/bootstrap-theme.min.css";
@import '../../../node_modules/bootstrap/less/bootstrap';
@import (inline) '../../../node_modules/select2/dist/css/select2.css';
@import (inline) '../../../node_modules/bootstrap3-wysihtml5-bower/dist/bootstrap3-wysihtml5.min.css';

@import "variables";
@import "lounge-ng";
@import "navbar-mosajco";
@import "dashboard";
@import "grid";
@import "cropper";
@import "widgets/user-chip";
@import "buttons";

//@btn-font-weight: 300;
//@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
//
//body, label, .checkbox label {
//  font-weight: 300;
//}



.post {
  .clearfix();
  padding: 15px;
  margin-bottom: 15px;
}

.copertina_utente {
  .foto_user {
    img {
      width: 150px;
      height: 150px;
      max-width: 150px;
    }
  }
}
.foto_user {
  img {
    width: 100px;
    height: 100px;
    max-width: 100px;
  }
}

.ruolo_user {
  padding: 0;
}

.main-footer {
  background-color: #333;
  color: white;

  .main-menu {
    background-color: #8a8a8a;
    display: flex;
    justify-content: center;

    a {
      color: white;
      &:hover {
        color: #333;
      }
    }
  }

  .footer-content {
    padding-top: 20px;
  }
}

img.user-avatar {
  width: 40px;
}

table {
  img.user-avatar {
    width: 25px;
  }
}

table.orders {
  td {
    white-space: nowrap;
  }
}

[data-mj-link] {
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
  }
}

.sidebar {
  display: none;
}

.sidebar-collapsed {
  .sidebar {
    max-width: 50px;

    a {
      //text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;

      span {
        display: none;
      }
    }

    //li:hover {
    //  overflow: visible;
    //  a {
    //    overflow: visible;
    //    span {
    //      display: inherit;
    //    }
    //  }
    //}
  }

  .main {
    width: ~"calc(100% - 50px)";
    margin-left: 50px;
  }
}

@media (min-width: 768px) {

  .sidebar {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #313131;
    border-right: 1px solid #eee;
  }
}

.nav-sidebar {
  margin-right: -21px;
  margin-bottom: 20px;
  margin-left: -20px;

  &>li {
    &>a {
      padding-right: 20px;
      padding-left: 20px;

      i {
        width: 15px;
        margin-right: 15px;
      }

    }


    &.active {
      &>a, &>a:hover, &>a:focus {
        color: #fff;
        background-color: #428bca;
      }
    }
  }
}

@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main {
  padding: 60px 20px 20px;
}

.page-header {
  margin-top: 0;

  .user-avatar {
    vertical-align: sub;
    display: inline;
  }
}

.page-subheader {
  margin-top: 0;
}

// Richiesta pagamento

.request-payments{
  border:1px solid #03a443;
  padding: 15px;
  max-width: 700px;
  margin: 0 auto;
  h1{
    font-size: 22px;
    color: #FF7F00;
    margin:5px 0 15px;
  }
  .total{
    background-color: #03a443;
    padding: 10px 0;
    h2{
      font-size: 18px;
      margin-top: 10px;
    }

    }
  }

p.select-ammount{
  padding:15px;
}

.ammount{
  margin-bottom:30px;

}

.tot-count{
   text-align: right;
   font-size: 12px;
   p{
     clear: both;
     //float: right;
     padding: 6px 0 0 0;
     margin: 0;
   }
}

.container-btn-total{
  margin: 0 auto;
  float: none;
  text-align: center;
  p{
    clear: both;
    float: none;
  }
  .btn-total{
    margin: 0 auto;
    float: none;
  }
}

.btn-bar{
  width: 100%;
  text-align: center;
  padding: 15px;
}

.clickable-row {
  cursor: pointer;
}

// SUMMARY PROFITS
.container-summary-chart{
  margin-top: 20px;
}

.container-summary-profits{
  margin-top:54px;
  .cel-summary-profits{
    background: #fff;
    float: left;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    color:#333;
    margin: 5px 0;
    .summary-profits-img{
      float: left;
      margin-top: 7px;
    }
    .summary-profits-name{
      float: left;
      font-size: 12px;
      margin-top: 5px;
      text-align: left;
    }
    .btn-verde{
      min-width: 100px;
      padding: 0px;
      display: inline-block !important;
      margin-top: 5px;

      .pull-right;
    }
  }
  .summary-profits-total{
    background: #03a443;
    border: 1px solid #027d33;
    color: #fff;
    text-shadow: 1px 1px #027d33;
    float: left;
    clear: both;
    width: 100%;
    padding:10px;
    font-size: 22px;
    margin-bottom:2px;
  }
  .summary-profits-request{
    background: #fff;
    float: left;
    width: 100%;
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-align: right;
    a{
      color:#000;
      text-decoration: underline;
      font-size:12px;
    }
  }
}

.dropdpwn-credits{
  background: #222;
  position: fixed;
  bottom: 0;
  right:0;
  min-width: 300px;
  @media (max-width: @screen-sm-max){
    width:100%;
  }

  .new-profits{
    padding: 10px;
    color: #fff;
    border-bottom: 1px solid #fff;
  }
  .cel-dropdown-credits{
    border-bottom: 1px solid #fff;
    color: #fff;
    float: left;
    width: 100%;
    padding: 10px;
    .content{
      float: left;
    }
    .dropdown-credits-img{
      float: left;
      margin-top: 3px;
    }
    .dropdown-credits-name{
      float: left;
      font-size: 12px;
      margin-top: 2px;
      text-align: left;
    }
    .dropdown-credits-date{
      font-size: 11px;
      color: #ddd;
      float: left;
      width: 100%;
    }
    .btn-verde{
      min-width: 100px;
      padding: 0px;
      display: inline-block !important;
      margin-top: 5px;

      .pull-right;
    }
  }
}

tr.text-center {
  th {
    .text-center();
  }
}

img.site-version {
  max-width: 60px;
}

.affix-container.affix {
  top: 60px;
}

.btn-link.text-danger {
  color: @brand-danger;
}

.trainer-container{
  position: fixed;
  top: 50px;
  left: 0;
  border: 1px solid;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12);
  z-index: 99999;
  height:~"calc(100% - 50px)";

  iframe{
    display: block;
  }
}

.trainer-invisible {
  display: none;
}

.trainer-hide {
  height: auto;
  border: none;
}

.tabbed-panel {
  .panel-body {
    border: 1px solid @panel-default-border;
    border-top-color: transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background: #ffffff;
  }
}

.text-preview {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid @panel-default-border;
}

.action-box {
  text-align: center;
  padding: 20px;
}

.wysihtml5-sandbox {
  min-height: 550px;
}