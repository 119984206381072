// colonna ad 1/5 della larghezza

@media (min-width: @screen-xs-max) {
  .col-1-5 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-left: @grid-gutter-width/2;
    padding-right: @grid-gutter-width/2;
    width: 20%;
  }
}

