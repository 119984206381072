@import (reference) "variables";

html.dashboard {
  background-color: #333;
}

.dashboard-container {
  .bg-login();
}

.dashboard-link {
  background-color: rgba(0,0,0,0.5);
  text-align: center;
  color: white;
  //padding: 20px;
  a, a:visited, a:hover {
    color: white;
    padding: 20px;
    display: block;
  }

  margin-top: 50px;
  margin-bottom: 50px;
}

.dashboard-panel {
  background-color: rgba(0,0,0,0.5);
  text-align: center;
  color: white;

  a {
    padding: 20px;
    text-align: center;
    display: block;

    &, &:visited, &:hover {
      color: white;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  margin-top: 50px;
  margin-bottom: 50px;

  .icon {
    width: 100%;
    background-color: red;
    min-height: 10px;
  }

  &.link {
    margin-bottom: 0;
    h4 {
      font-size: 14px;
      text-decoration: underline;
    }
  }

  &.no-margin {
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.icon-restricted-area {
  background-image: url(/assets/images/icons/areariservata.png);
}

.dashboard-box {
  header {
    h5 {
      margin: 0;
      color: white;
    }
    padding: 8px;
  }

  .box-body {
    padding: 8px;
    min-height: 60px;
    p {
      margin: 0;
    }
  }

  margin-bottom: 20px;
}

.box-profits {
  header {
    background-color: @box-profits-header-bg;
  }
  .box-body {
    background-color: @box-profits-body-bg;
  }
}

.box-expiring-sites {
  header {
    background-color: @box-expiring-sites-header-bg;
  }
  .box-body {
    background-color: @box-expiring-sites-body-bg;
  }
}

.box-my-team {
  header {
    background-color: @box-my-team-header-bg;
  }
  .box-body {
    background-color: @box-my-team-body-bg;
  }
}

.box-ranking {
  header {
    background-color: @box-ranking-header-bg;
  }
  .box-body {
    background-color: @box-ranking-body-bg;
  }
}

.box-portfolio {
  header {
    background-color: @box-portfolio-header-bg;
  }
  .box-body {
    background-color: @box-portfolio-body-bg;
  }
}
