table {
  .user-chip {
    .user-avatar {
      width: 20px;
    }

    .media-heading {
      margin-bottom: 0;
      font-size: 14px;
    }

    .media-body {
      width: auto;
    }
  }
}