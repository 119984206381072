.avatar-cropper {
  margin: 50px auto;
  width: 200px;
}
.cropit-image-preview {
  /* You can specify preview size in CSS */
  width: 200px;
  height: 200px;
}

/* Show load indicator when image is being loaded */
.cropit-image-preview.cropit-image-loading .spinner {
  opacity: 1;
}

/* Show move cursor when image has been loaded */
.cropit-image-preview.cropit-image-loaded {
  cursor: move;
}

/* Gray out zoom slider when the image cannot be zoomed */
.cropit-image-zoom-input[disabled] {
  opacity: .2;
}

/*!* Hide default file input button if you want to use a custom button *!*/
/*input.cropit-image-input {*/
/*visibility: hidden;*/
/*}*/

/* The following styles are only relevant to when background image is enabled */

/* Translucent background image */
.cropit-image-background {
  opacity: .15;
}

/* Style the background image differently when preview area is hovered */
.cropit-image-background.cropit-preview-hovered {
  opacity: .3;
}

/*
 * If the slider or anything else is covered by the background image,
 * use non-static position on it
 */
input.cropit-image-zoom-input {
  position: relative;
}